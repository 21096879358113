// @flow
import React from 'react';
import IntroBlock from '../shared/IntroBlock';

const Intro = () => (
  <div id="intro">
    <IntroBlock
      title="導入のポイント"
      subTitle="プリペイドカードいらず"
      text="プリペイドカードを購入するために、わざわざコンビニなどに行く必要がなくなるので、<br/>「今すぐコンテンツを楽しみたい」というユーザーの希望を叶えることができます。"
    />
  </div>
);

export default Intro;
