// @flow
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { blocks } from './const';
import Company from '../shared/Company';
import Intro from './Intro';
import CaseStudies from '../shared/CaseStudies';
import Start from '../shared/Start';
import NavigationBlock from '../shared/NavigationBlock';
import { getMerchantsIds, getCasesIds } from '../utils';
import * as MerchantActions from '../../../redux/merchant/actions';
import Merchants from '../shared/Merchants';
import { navigationStyles } from './styles';

const Navigation = () => {
  const digitalPage = useSelector((state) => state.merchantPages.digitalPage);
  const dispatch = useDispatch();
  const classes = navigationStyles({});

  // Did mount
  useEffect(() => {
    dispatch(MerchantActions.getPageDigital());
  }, [dispatch]);

  const merchantsIds = getMerchantsIds(digitalPage);
  const casesIds = getCasesIds(digitalPage);

  return (
    digitalPage && (
      <NavigationBlock blocks={blocks}>
        <Company
          title="ポイントや電子書籍、動画の購入など、デジタルコンテンツでもお使いいただけます。"
          subTitle="導入企業"
        >
          {merchantsIds && (
            <>
              <div className={classes.pc}>
                <Merchants
                  perRow={4}
                  amount={4}
                  merchantsIds={merchantsIds}
                  logoStyles={{ background: 'white' }}
                />
              </div>
              <div className={classes.sp}>
                <Merchants
                  perRow={2}
                  amount={4}
                  merchantsIds={merchantsIds}
                  logoStyles={{
                    background: 'white',
                    width: '160px',
                    height: '50px',
                  }}
                />
              </div>
            </>
          )}
        </Company>
        <Intro />
        {casesIds && <CaseStudies casesIds={casesIds} />}
        <Start />
      </NavigationBlock>
    )
  );
};

export default Navigation;
