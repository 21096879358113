// @flow
import React from 'react';
import Layout from '../../../components/Layout/Layout';
import ApplySection from '../../../components/Merchant/ApplySection';
import Navigation from '../../../components/Merchant/Digital/Navigation';
import Hero from '../../../components/Merchant/shared/Hero';

const GoodsPage = () => (
  <Layout isMerchant>
    <Hero title="業種別の使い方" subTitle="デジタルコンテンツ" />
    <section>
      <Navigation />
    </section>
    <ApplySection />
  </Layout>
);

// $FlowFixMe
export default GoodsPage;
